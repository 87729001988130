export const newsItems = [
  {
    id: 1,
    newsHref: "https://news.mtn.co.kr/news-detail/2023072816412791134",
    newsSrc: "/assets/mediaThumb.jpg",
    newsAlt: "news01",
    newsName: "MTN뉴스",
    newsTitle: "STO 컨설팅업체 크로스체크, 김준영 스페셜이 찍었다",
    newsContent:
      "STO 컨설팅업체 크로스체크는 28일 와우넷 대표 파트너인 김준영 스페셜로부터 투자를 유치했다고 밝혔다.",
    newsDate: "2023.07.28",
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
];
