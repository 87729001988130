export const partnerItems = [
  {
    id: 1,
    partnerSrc: "/assets/crosscheckLogo.jpg",
    partnerAlt: "crosscheck",
  },
  {
    id: 2,
    partnerSrc: "/assets/aimrichLogo.jpg",
    partnerAlt: "aimrich",
  },
  {
    id: 3,
    partnerSrc: "/assets/baroLogo.jpg",
    partnerAlt: "baro",
  },
  {
    id: 4,
    partnerSrc: "/assets/detailsLogo.jpg",
    partnerAlt: "Details",
  },

  // {
  //   id: 5,
  //   partnerSrc: "/assets/leading.png",
  //   partnerAlt: "leading",
  // },
  // {
  //   id: 6,
  //   partnerSrc: "/assets/aju.png",
  //   partnerAlt: "aju",
  // },
  // {
  //   id: 7,
  //   partnerSrc: "/assets/union.png",
  //   partnerAlt: "union",
  // },
  // {
  //   id: 8,
  //   partnerSrc: "/assets/hashed.png",
  //   partnerAlt: "hashed",
  // },
  // {
  //   id: 9,
  //   partnerSrc: "/assets/hana.png",
  //   partnerAlt: "hana",
  // },
  // {
  //   id: 10,
  //   partnerSrc: "/assets/shinhan.png",
  //   partnerAlt: "shinhan",
  // },
  // {
  //   id: 10,
  //   partnerSrc: "/assets/ibk.png",
  //   partnerAlt: "ibk",
  // },
  // {
  //   id: 11,
  //   partnerSrc: "/assets/nh.png",
  //   partnerAlt: "nh",
  // },
  // {
  //   id: 12,
  //   partnerSrc: "/assets/mandiri.png",
  //   partnerAlt: "mandiri",
  // },
  // {
  //   id: 13,
  //   partnerSrc: "/assets/ssnc.png",
  //   partnerAlt: "ssnc",
  // },
  // {
  //   id: 14,
  //   partnerSrc: "/assets/cloudwall.png",
  //   partnerAlt: "cloudwall",
  // },
  // {
  //   id: 15,
  //   partnerSrc: "/assets/korbit.png",
  //   partnerAlt: "korbit",
  // },
  // {
  //   id: 16,
  //   partnerSrc: "/assets/gopax.png",
  //   partnerAlt: "gopax",
  // },
  // {
  //   id: 17,
  //   partnerSrc: "/assets/hankyung.png",
  //   partnerAlt: "hankyung",
  // },
  // {
  //   id: 18,
  //   partnerSrc: "/assets/bloomingbit.png",
  //   partnerAlt: "bloomingbit",
  // },
  // {
  //   id: 19,
  //   partnerSrc: "/assets/fnp.png",
  //   partnerAlt: "fnp",
  // },
  // {
  //   id: 20,
  //   partnerSrc: "/assets/market-vector.png",
  //   partnerAlt: "market-vector",
  // },
  // {
  //   id: 21,
  //   partnerSrc: "/assets/vinter.png",
  //   partnerAlt: "vinter",
  // },
  // {
  //   id: 22,
  //   partnerSrc: "/assets/chainlink.png",
  //   partnerAlt: "chainlink",
  // },
  // {
  //   id: 23,
  //   partnerSrc: "/assets/fsn.png",
  //   partnerAlt: "fsn",
  // },
  // {
  //   id: 24,
  //   partnerSrc: "/assets/seoultech.png",
  //   partnerAlt: "seoultech",
  // },
];
