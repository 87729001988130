import axios from "axios";
import { signatureImages, signatureItems } from "../../datas/aboutItems";
import Head from "../../components/commons/Headline/Head";
import {
  IdentityBox,
  IdentityDesc,
  IdentityDownBtnWrap,
  IdentityItems,
  IdentityTitle,
  IdentityWrap,
  LogoDown,
  LogoTop,
  SignatureLogo,
  SignatureLogoColBox,
  SignatureLogoLeft,
  SignatureLogoRight,
  SignatureLogoRow,
  SignatureLogoRowBox,
} from "../../styles/about/aboutIdentityStyle";
import { theme } from "../../themes/themes";

const AboutIdentity = () => {
  const handleZipDownload = async () => {
    try {
      const response = await axios.get(
        "https://sendemail.qtdata.co.kr/api/downloadZip",
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/zip",
            "Content-Disposition": "attachment; filename=qtdataCI.zip",
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/zip" });

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "qtdataCI.zip";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("오류메세지:", (error as Error).message);
    }
  };

  return (
    <IdentityWrap>
      <Head content="Identity" color={theme.colors.sub} />
      <IdentityTitle>Signature Logo</IdentityTitle>
      <IdentityDesc
        dangerouslySetInnerHTML={{ __html: signatureItems[0].desc }}
      />

      <IdentityDownBtnWrap onClick={handleZipDownload}>
        Download Logo
      </IdentityDownBtnWrap>

      <IdentityBox>
        <IdentityItems>
          <SignatureLogoRowBox>
            <SignatureLogoRow>
              <SignatureLogo
                src={signatureImages[0].src}
                alt={signatureImages[0].alt}
              />
            </SignatureLogoRow>
          </SignatureLogoRowBox>
          <SignatureLogoColBox>
            <SignatureLogoLeft>
              <LogoTop
                src={signatureImages[1].src}
                alt={signatureImages[1].alt}
              />
            </SignatureLogoLeft>
            <SignatureLogoRight>
              <LogoTop
                src={signatureImages[2].src}
                alt={signatureImages[2].alt}
              />
            </SignatureLogoRight>
          </SignatureLogoColBox>
        </IdentityItems>

        <IdentityItems>
          <IdentityTitle>Symbol Logo</IdentityTitle>
          <IdentityDesc
            dangerouslySetInnerHTML={{ __html: signatureItems[1].desc }}
          />
          <SignatureLogoColBox>
            <SignatureLogoLeft>
              <LogoDown
                src={signatureImages[3].src}
                alt={signatureImages[3].alt}
              />
            </SignatureLogoLeft>
            <SignatureLogoRight>
              <LogoDown
                src={signatureImages[4].src}
                alt={signatureImages[4].alt}
              />
            </SignatureLogoRight>
          </SignatureLogoColBox>
        </IdentityItems>
      </IdentityBox>
    </IdentityWrap>
  );
};

export default AboutIdentity;
