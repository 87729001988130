import { NavItem } from "../types/navTypes";

export const NavMenus: NavItem[] = [
  { label: "Home", path: "/" },
  { label: "About Us", path: "/about" },
  { label: "주식박스", path: "/joosikbox" },
  { label: "Press", path: "/press" },
  { label: "Recruit", path: "/recruit" },
  { label: "Contact", path: "/contact" },
];
