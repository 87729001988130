import styled from "styled-components";

export const PartnerWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 80px;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 80px;

  @media only screen and (max-width: 800px) {
    width: 88%;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Partners = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 60px;
  gap: 24px;
  max-width: 1440px;
  width: 100%;

  @media only screen and (max-width: 800px) {
    align-items: center;
    row-gap: 40px;
  }
`;

export const PartnerBox = styled.div`
  width: 308px;
  height: 112px;

  @media only screen and (max-width: 800px) {
    align-items: center;
    width: 154px;
    height: 50px;
  }
`;

export const PartnerImg = styled.img`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  max-width: 100%;
`;

export const HiddenDiv = styled.div`
  display: none;
  width: 100%;
  height: 8px;

  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
