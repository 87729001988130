export const recruitItems = [
  {
    title: "전략기획",
    items: [
      {
        id: 1,
        partName: "1. 서비스 기획",
        partDesc:
          "새로운 서비스를 만들기 위해 아이디어를 기획하며, 팀과 협업하여 서비스를 개발하고 향상시키는 역할을 합니다. 사용자를 이해하고, 지속적인 개선을 위해 피드백을 수집하고 분석합니다.",
      },
      {
        id: 2,
        partName: "2. IR담당",
        partDesc:
          "회사의 재무 및 비즈니스 전략에 대한 정보를 투자자들과 공유하는 역할을 합니다. 금융 보고서 작성 등을 통해 회사의 가치를 높이고 투자자 신뢰를 유지합니다.",
      },
      {
        id: 3,
        partName: "3. PM",
        partDesc:
          "프로젝트를 성공적으로 이끌기 위해 계획, 조직, 관리, 조정하며 일정과 예산을 지키고, 팀원들 간의 협업과 의사소통을 원활하게 조율합니다.",
      },
    ],
  },
  {
    title: "운영",
    items: [
      {
        id: 4,
        partName: "1. 정책 운영",
        partDesc:
          "새로운 정책을 만들고 기존 정책을 관리하며, 정책의 실행과 평가를 통해 개선점을 찾고 조직의 성장과 안정성을 증진시킵니다.",
      },
      {
        id: 5,
        partName: "2. 정부관련 사업 운영",
        partDesc:
          "정부나 공공기관에서 주도하는 프로젝트나 사업을 관리합니다. 프로젝트 계획, 예산 및 일정 관리 등을 통해 정부 정책 목표를 지원하고 사회에 긍정적인 영향을 미치는 역할을 수행합니다.",
      },
      {
        id: 6,
        partName: "3. CS",
        partDesc:
          "고객 문의 처리, 문제 해결, 정보 제공 등을 통해 고객들의 만족도를 유지하는 역할을 합니다. 고객과의 소통을 통해 회사의 서비스 품질 향상과 고객 경험 개선에 기여합니다. ",
      },
      {
        id: 7,
        partName: "4. 협력사관리",
        partDesc:
          "조직과 외부 협력사 간의 관계를 관리합니다. 협력사 선택, 계약 관리, 의사 소통, 문제 해결, 비용 관리 등을 통해 협력사와의 원활한 협업을 유지하고 조직의 목표를 달성합니다.",
      },
    ],
  },
  {
    title: "홍보",
    items: [
      {
        id: 8,
        partName: "1. 언론 홍보",
        partDesc:
          "보도 자료 작성 등을 통해 브랜드의 홍보와 인지도 향상을 도모합니다. 또한, 언론 보도를 모니터링하고 위기 상황에 대비하여 위기 대응 전략을 준비하는 등 다양한 활동을 수행합니다.",
      },
      {
        id: 9,
        partName: "2. 커뮤니티 채널 관리",
        partDesc:
          "사용자들과 상호작용하며 콘텐츠를 제작하고 공유하여 브랜드 인지도를 높이고, 커뮤니티의 활발한 활동을 유지합니다. 커뮤니티 활동을 분석하여 전략을 개선하는 역할을 합니다.",
      },
      {
        id: 10,
        partName: "3. 미디어 콘텐츠 관리",
        partDesc:
          "미디어 콘텐츠를 기획, 제작, 관리하는 역할을 맡습니다. 주로 다양한 디지털 플랫폼이나 채널을 활용하여 콘텐츠를 관리하고 전략적으로 활용합니다.",
      },
    ],
  },
  {
    title: "경영지원",
    items: [
      {
        id: 11,
        partName: "1. 재무 회계",
        partDesc:
          "회사의 재무 거래를 기록하고 보고서를 작성합니다. 재무 분석, 예산 설정, 세무 신고, 내부 통제 평가 등을 통해 회사의 재무 건전성과 성과를 관리합니다.",
      },
      {
        id: 12,
        partName: "2. 인사 총무",
        partDesc:
          "조직 내의 인적 자원을 관리합니다. 이는 채용, 인사 정책 운영, 급여 관리, 법규 준수, 교육 및 휴가 관리, 조직 문화 개선 등을 포함합니다. 또한, 조직 내부의 원활한 인사 활동을 유지합니다",
      },
      {
        id: 13,
        partName: "3. 사내 제도 및 복지",
        partDesc:
          "직원들의 복지와 관련된 정책 및 제도를 관리합니다. 이는 복리후생 제도, 규정 관리, 상담 지원, 사내 이벤트 조직 등을 포함하며, 직원들의 만족도와 조직 내 문화를 증진시키는데 기여합니다.",
      },
    ],
  },
  {
    title: "개발",
    items: [
      {
        id: 14,
        partName: "1. Front-end 개발",
        partDesc:
          "웹사이트나 애플리케이션의 사용자 인터페이스(UI)를 만들며, front-end 개발 언어를 사용하여 구현합니다. 웹 호환성, 성능 최적화 등을 담당하여 웹사이트의 외관과 기능을 개선합니다.",
      },
      {
        id: 15,
        partName: "2. Back-end 개발",
        partDesc:
          "웹사이트나 애플리케이션의 서버 측에서 데이터 처리, 보안, API 개발, 비즈니스 로직 구현 등을 담당합니다. 서버와 데이터베이스를 관리하고 시스템 안정성과 성능을 유지 및 향상시킵니다.",
      },
      {
        id: 16,
        partName: "3. DBA",
        partDesc:
          "DB의 설계와 모니터링, 보안 관리, 백업 및 복구, 성능 튜닝, 버전 관리, 문서화 등을 담당하여 데이터를 안전하고 효율적으로 관리합니다.",
      },
      {
        id: 17,
        partName: "4. Infra",
        partDesc:
          "시스템 및 네트워크 관리, 데이터 보호, 백업 및 복구, 클라우드 및 가상화 기술 관리, 성능 최적화, 기술 지원, 규정 준수 등을 담당하여 IT 인프라를 안정적으로 유지하고 업무 지원을 합니다.",
      },
    ],
  },
  {
    title: "디자인",
    items: [
      {
        id: 18,
        partName: "1. UI/UX 디자인",
        partDesc:
          "사용자 연구, 와이어프레임 및 프로토타입 작성, 그래픽 디자인, 사용자 테스트 등을 통해 사용자 중심의 직관적이고 매력적인 디자인을 만들어내며 지속적으로 UI/UX를 개선합니다",
      },
      {
        id: 19,
        partName: "2. 브랜딩 디자인",
        partDesc:
          "로고, 시각적 아이덴티티, 패키지, 광고물 등을 디자인하여 브랜드 이미지를 구축합니다. 시각적인 요소를 활용하여 브랜드의 메시지를 전달하고 브랜드의 일관성을 유지하는 역할을 수행합니다.",
      },
      {
        id: 20,
        partName: "3. 편집 디자인",
        partDesc:
          "출판물이나 인쇄물의 디자인과 레이아웃을 담당합니다. 책, 잡지, 카탈로그 등을 디자인하고 텍스트, 이미지, 레이아웃 등을 조율하여 완성도 있는 디자인 작업을 수행합니다.",
      },
    ],
  },
];
